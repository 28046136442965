<template>
  <!-- 仓库调入单明细 -->
  <div class="check-detail">
    <StoreDetail
      :billType="billType"
      :uuid="uuid"
      :orderInfo="orderInfo"
      :shopType="shopType"
      ref="storeDetail"
      :tableMiddleSpecialCount="1"
    >
      <!-- 插槽 -->
      <template #selectOneArea="scoped">
        <!-- <div class="searchItem">
          <el-form-item label="单据日期" prop="billDate">
            <el-date-picker
              v-model="scoped.form.billDate"
              type="date"
              placeholder="选择日期"
              :disabled="scoped.disabled"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
        </div> -->
        <div class="searchItem">
          <el-form-item label="调出仓库" prop="outStoreId">
            <!-- 仓库自定义下拉框 -->
            <SelectFrame
              ref="selectOutStore"
              v-model="scoped.form.outStoreName"
              :tableData="scoped.storeData"
              :disabled="['130106'].includes(billType) ? true : scoped.disabled"
              @getPitchId="scoped.getDiyselectId($event, 'outStoreId', 'form')"
              @clickAdd="handleStore('add', 'outStoreId')"
              @click="handleStore('update', 'outStoreId')"
              :normalizer="scoped.normalizerStore"
            ></SelectFrame>
          </el-form-item>
        </div>
        <div class="searchItem">
          <el-form-item label="调入仓库" prop="inStoreId">
            <!-- 仓库自定义下拉框 -->
            <SelectFrame
              ref="selectInStore"
              v-model="scoped.form.inStoreName"
              :tableData="scoped.storeData"
              :disabled="
                $route.query.billId && $route.query.type == 'Add' ? true : scoped.disabled
              "
              @getPitchId="scoped.getDiyselectId($event, 'inStoreId', 'form')"
              @clickAdd="handleStore('add', 'inStoreId')"
              @click="handleStore('update', 'inStoreId')"
              :normalizer="scoped.normalizerStore"
            ></SelectFrame>
          </el-form-item>
        </div>
      </template>
      <template #tableMiddleSpecial>
        <el-table-column
          label="调出数量"
          align="center"
          prop="importUnitQty"
          show-overflow-tooltip
          width="120"
        >
        </el-table-column>
      </template>
    </StoreDetail>
    <!-- 选择仓库对话框 -->
    <selectStore :OpenStore.sync="OpenStore" @getStore="getStore"></selectStore>
    <!-- 新增仓库对话框 -->
    <storeAdd :OpenStoreAdd.sync="OpenStoreAdd" @getStore="getStore"></storeAdd>
  </div>
</template>

<script>
import StoreDetail from "@/views/components/bill/storeDetail.vue";
import selectStore from "@/views/components/selectStore"; //选择仓库组件
import storeAdd from "@/views/components/selectStore/storeAdd.vue"; //新增仓库组件
export default {
  name: "storeMoveInDetailCom",
  components: { StoreDetail, selectStore, storeAdd },
  data() {
    return {
      shopType: 1, //仓库类型
      billType: "",
      //打开选择仓库开关
      OpenStore: false,
      //打开新增仓库开关
      OpenStoreAdd: false,
      //仓库类型
      storeType: undefined,
      uuid: "1b5c0d5b-1994-a6b1-6864-baa574970466",
      //搜索框ref
      ref: undefined,
      orderInfo: undefined,
    };
  },
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      // 访问组件实例 vm 中的数据和方法
      vm.shopType = vm.$route.query.shopType ? Number(vm.$route.query.shopType) : 1;
      vm.billType = "130106";
      if (vm.$route.query.type == "Add" && vm.$route.query.orderInfo) {
        console.log("vm.$route.query1", vm.$route.query);
        vm.orderInfo = JSON.parse(vm.$route.query.orderInfo);
      }
      console.log("进来了嘛?", vm.billType);
    });
  },
  methods: {
    //新增仓库/选择仓库
    handleStore(type, name) {
      if (type === "add") {
        this.OpenStoreAdd = true;
      } else {
        this.OpenStore = true;
      }
      if (name === "outStoreId") {
        this.storeType = name;
        this.ref = "selectOutStore";
      } else {
        this.storeType = name;
        this.ref = "selectInStore";
      }
    },
    //调用storeDetail组件的方法  把选中的仓库/新增仓库传给下拉框
    getStore(event) {
      this.$refs.storeDetail.getStore(event, "form", this.storeType, this.ref, true);
    },
  },
};
</script>
